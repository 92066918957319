
import { Options, Vue } from 'vue-class-component';
import { orderModule } from '@/modules/order/orderModule.vuex-module';
import { Time } from '@/common/models/Time.model';
import { Prop, ProvideReactive } from 'vue-property-decorator';
import { Item } from '@/common/models/Item.model';
import OrderActivity from '@/modules/order/common/classes/OrderActivity.class';
import moment from 'moment';
import { Consent } from '@/common/models/Consent.model';
import { OrderEntityTimeAddOn } from '@/common/models/OrderEntityTime.model';
import { Watch } from 'vue-property-decorator';

@Options({
	name: 'TimeView',
	components: {},
})
export default class TimeView extends Vue {
	@Prop({ default: '0' }) orderEntityTimeKey!: string;
	success = false;
	paid = false;
	mounted(): void {
		orderModule.selectByOrderEntityTimeKey(this.orderEntityTimeKey);
		orderModule.needsToAbandon = false;
		this.checkParams();
	}
	@Watch('$route') routeWatcher(to: any, from: any): void {
		if (from.name != 'OrderTime') this.checkParams();
	}
	checkParams(): void {
		this.success = this.$route.query.success == 'true';
		this.paid = this.$route.query.success == 'true';
		this.$router.replace({ query: {} });
	}
	get item(): Item | null {
		return orderModule.openItem;
	}
	get activities(): OrderActivity[] {
		return orderModule.itemActivities ?? [];
	}
	get activity(): OrderActivity | null {
		return orderModule.openActivity;
	}
	get showModal(): boolean {
		return !!this.$route.meta.modal;
	}
	get addons(): OrderEntityTimeAddOn[] {
		return this.time?.AddOns.filter((a) => !a.IsDeleted) ?? [];
	}
	get cancellationPolicy(): string {
		return orderModule.openActivity?.activity.AllowSelfCancellation ? '' : orderModule.openActivity?.activity.CancellationPolicy ?? '';
	}
	get reschedulePolicy(): string {
		return orderModule.openActivity?.activity.AllowSelfReschedule ? '' : orderModule.openActivity?.activity.ReschedulePolicy ?? '';
	}
	get canAddWaiver(): boolean {
		return !!orderModule.openActivity?.activity.AllowAddWaivers;
	}
	get waiverRequired(): boolean {
		return !!orderModule.openActivity?.activity.ConsentRequired;
	}
	@ProvideReactive() get time(): Time | undefined {
		return orderModule._openActivity?.selectedTimes.find((t) => t.OrderEntityTimeKey == this.orderEntityTimeKey);
	}
	age(consent: Consent): number {
		return moment(Date()).diff(consent.DateOfBirth, 'years');
	}
	agreed(consent: Consent): string {
		return moment(consent.DateAgreed).format('M/D/yyyy @ hh:mma');
	}
	edit(): void {
		console.log('edit');
	}
	cancel(): void {
		console.log('edit');
	}
	back(): void {
		orderModule.abandonOrderChanges();
		this.$router.push({ name: 'OrderLanding' });
	}
}
